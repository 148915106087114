@import '_mixins';
.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 510px);
}

.login-logo {
    background-color: #000;
    border-radius: 100%;
    padding: 50px;
    margin-right: 100px;
}
.he-relative {
    position: relative;
}
//input
.he-input {
    padding: 12px 0;
    font-size: 18px;
    @include lh-unitless(20, 18);
    font-weight: 500;
    color: black;
    border: none;
    background-color: transparent;
    // border-bottom: 3px solid black;
    border-radius: 0;
    @include transition(all, 0.3s);
    &:focus {
        outline: none;
    }
    &[type=submit] {
        &:hover {
            cursor: pointer;
        }
    }
    &::-webkit-input-placeholder {
        color: black;
    }
    &::-moz-placeholder {
        color: black;
    }
    &:-ms-input-placeholder {
        color: black;
    }
    &:-moz-placeholder {
        color: black;
    }
    &-transparent {
      background-color: transparent;
    }
    &__icon {
      top: 10px;
      left: 10px;
      &-padding {
        padding-left: 50px;
        + .he-input-label {
          left: 50px;
        }
      }
    }
    &__error {
      border-color: red;
      &-msg {
        color: red;
      }
    }
    &-holder {
       
        border-bottom: 3px solid black;
      input:placeholder-shown + .he-input-label {
        @include multi-transform(translateY(0) scale(1));
      }
      textarea:placeholder-shown + .he-input-label {
        @include multi-transform(translateY(0) scale(1));
      }
      input:focus + .he-input-label {
        @include multi-transform(translateY(-100%) scale(0.8));
      }
      textarea:focus + .he-input-label {
        @include multi-transform(translateY(-100%) scale(0.8));
      }
    }
    &-label {
        position: absolute;
        top: 25%;
        left: 0;
        font-size: 18px;
        color: black;
        -webkit-transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        -moz-transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        -ms-transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        -o-transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        transform-origin: 0 0;
        @include multi-transform(translateY(-100%) scale(0.8));
        @include lh-unitless(20, 18);
    }
  }

  .he-mb-40 {
    margin-bottom: 40px;
  }

  .mr-10 {
    margin-right: 10px;
  }