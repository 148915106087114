.spinnerCompass {
    animation: spin-anim 6s ease-in;
}

@keyframes spin-anim {
    0% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}