.slick-track {
    display: flex;
    width: 100%;
    align-items: center;
}

.slick-arrow  {
    position: relative;
    z-index: 10;
}

#teamde,
#teamnl,
#teamcz {
    .slick-track .slick-slide .slider-item-img {
        object-fit: cover;
        max-height: 400px;
        width: 144.5px;
        height: 180.18px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-slide .slider-items-team {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 410px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-current.slick-active .slider-item-img {
        height: 400px;
        // width: 400px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-slide {
        // -webkit-transform: scale(1.2);
        // -moz-transform: scale(1.2);
        // transform: scale(1.2);
        // transform: rotate(2deg);
        img {
            padding: 30px 15px 15px 15px;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            // transform: rotate(1deg);
            -webkit-transition: all 0.5s ease;
            -moz-transition: all  0.5s ease;
            -ms-transition: all  0.5s ease;
            -o-transition: all  0.5s ease;
            transition: all  0.5s ease;
        }
    }

    .slick-track  .slick-slide,
    .slick-track .slick-cloned .slider-item-img,
    .slick-track .slick-slide .slider-item-img {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }


    .slick-track .slick-active.slick-current .slider-item-img {
        width: 385.04px;
        height: 345.49px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        img {
            padding: 35px 30px 40px 28px;
        }
    }
    .slick-track .slick-slide.mystyle {
        .slider-item-img {
            margin-left: 6vw;
            width: 225.04px;
            height: 255.49px;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all  0.5s ease;
            -ms-transition: all  0.5s ease;
            -o-transition: all  0.5s ease;
            transition: all  0.5s ease;
            img {
                padding: 35px 25px 25px 25px;
            }
        }
    }

    .slick-track .mystyleExtra {
        .slider-item-img {
            margin-left: 6vw;
            width: 225.04px;
            height: 255.49px;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all  0.5s ease;
            -ms-transition: all  0.5s ease;
            -o-transition: all  0.5s ease;
            transition: all  0.5s ease;
            img {
                padding: 35px 25px 25px 25px;
            }
        }
    }

    .slider-item-img::before {
        content: '';
        position: absolute;
        background: url("./bg.svg");
        background-position: top;
        background-repeat: repeat-y;
        background-size: contain;
        // filter: brightness(.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .slider-item-img::after {
        content: '';
        position: absolute;
        background: url(./circle.svg);
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        left: 0;
        top: 7px;
        padding: 2px;
        height: 20px;
        width: 100%;
        bottom: 0;
        z-index: -1;
    }

    .slick-track .slick-slide.slick-active.slick-current span.btn-previous img{
        display: block;
        position: absolute;
        left: 20px;
        height: 20px;
        width: 60px;
        padding: 0;
        bottom: 5px;
        object-fit: contain;
        transform: rotate(180deg);
        cursor: pointer;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        &:hover {
            left: 15px;
        }
    }
    .slick-track .slick-slide.slick-active.slick-current span.btn-next img{
        display: block;
        position: absolute;
        right: 20px;
        height: 20px;
        width: 60px;
        padding: 0;
        bottom: 5px;
        object-fit: contain;
        cursor: pointer;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        &:hover {
            right: 15px;
        }
    }
    .slick-track .slick-slide.slick-active.slick-current span.btn-next,
    .slick-track .slick-slide.slick-active.slick-current span.btn-previous{
        display: block;
        opacity: 1;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity  0.5s ease;
        -ms-transition: opacity  0.5s ease;
        -o-transition: opacity  0.5s ease;
        transition: opacity  0.5s ease;
    }
    .slick-track .slick-slide span.btn-next,
    .slick-track .slick-slide span.btn-previous{
        display: none;
        opacity: 0;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity  0.5s ease;
        -ms-transition: opacity  0.5s ease;
        -o-transition: opacity  0.5s ease;
        transition: opacity  0.5s ease;
    }
}

#teamall {
    .slick-track .slick-slide .slider-item-img {
        object-fit: cover;
        max-height: 400px;
        width: 144.5px;
        height: 180.18px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-slide .slider-items-team {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 410px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-current.slick-active .slider-item-img {
        height: 400px;
        // width: 400px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }

    .slick-track .slick-slide {
        // -webkit-transform: scale(1.2);
        // -moz-transform: scale(1.2);
        // transform: scale(1.2);
        // transform: rotate(2deg);
        img {
            padding: 30px 15px 15px 15px;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            // transform: rotate(1deg);
            -webkit-transition: all 0.5s ease;
            -moz-transition: all  0.5s ease;
            -ms-transition: all  0.5s ease;
            -o-transition: all  0.5s ease;
            transition: all  0.5s ease;
        }
    }

    .slick-track  .slick-slide,
    .slick-track .slick-cloned .slider-item-img,
    .slick-track .slick-slide .slider-item-img {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
    }


    .slick-track .slick-active.slick-current .slider-item-img {
        width: 385.04px;
        height: 345.49px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        img {
            padding: 35px 30px 40px 28px;
        }
    }

    .slider-item-img::before {
        content: '';
        position: absolute;
        background: url("./bg.svg");
        background-position: top;
        background-repeat: repeat-y;
        background-size: contain;
        // filter: brightness(.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .slider-item-img::after {
        content: '';
        position: absolute;
        background: url(./circle.svg);
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        left: 0;
        top: 7px;
        padding: 2px;
        height: 20px;
        width: 100%;
        bottom: 0;
        z-index: -1;
    }

    .slick-track .slick-slide.slick-active.slick-current span.btn-previous img{
        display: block;
        position: absolute;
        left: 20px;
        height: 20px;
        width: 60px;
        padding: 0;
        bottom: 5px;
        object-fit: contain;
        transform: rotate(180deg);
        cursor: pointer;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        &:hover {
            left: 15px;
        }
    }
    .slick-track .slick-slide.slick-active.slick-current span.btn-next img{
        display: block;
        position: absolute;
        right: 20px;
        height: 20px;
        width: 60px;
        padding: 0;
        bottom: 5px;
        object-fit: contain;
        cursor: pointer;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all  0.5s ease;
        -ms-transition: all  0.5s ease;
        -o-transition: all  0.5s ease;
        transition: all  0.5s ease;
        &:hover {
            right: 15px;
        }
    }
    .slick-track .slick-slide.slick-active.slick-current span.btn-next,
    .slick-track .slick-slide.slick-active.slick-current span.btn-previous{
        display: block;
        opacity: 1;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity  0.5s ease;
        -ms-transition: opacity  0.5s ease;
        -o-transition: opacity  0.5s ease;
        transition: opacity  0.5s ease;
    }
    .slick-track .slick-slide span.btn-next,
    .slick-track .slick-slide span.btn-previous{
        display: none;
        opacity: 0;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity  0.5s ease;
        -ms-transition: opacity  0.5s ease;
        -o-transition: opacity  0.5s ease;
        transition: opacity  0.5s ease;
    }
}

