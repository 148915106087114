.dashboard {
    display: flex;
    padding: 80px 10px;

    &-menu {
        padding-left: 0;
        list-style: none;
        height: 100%;
        position: sticky;
        padding-right: 30px;
        border-right: 2px solid #000;
    }
}

.d-flex-none {
    flex: none;
}
.content-dashboard {
    padding: 15px;
}

.button-dashboard {

    .toggleButton{
        padding-top: 6px;
        display: flex;
        align-items: center;
    }
    
    .toggleButton__body{
        position: relative;
        display: inline-block;
        width: 7em;
        height: 2em;
        cursor: pointer;
        transition: .1s ease-in all;
        border-radius: 1.25em;
        background: #ddd linear-gradient(135deg, #dedede, #ddd);
        box-shadow: 1px 1px 3px rgba(0,0,0,.3) inset;
    }
    
    .toggleButton__body:before{
        position: absolute;
        top: 0em;
        left: 1em;
        right: 1em;
        content: "HIDE";
        font-size: .75em;
        line-height: 2.88em;
        text-align: right;
        font-family: sans-serif;
        font-weight: bold;
        color: #aaa;
    }
    
    .toggleButton__body:after{
        position: absolute;
        top: 2px;
        left: 2px;
        display: block;
        width: calc(2em - 4px);
        height: calc(2em - 4px);
        content: "";
        transition: .1s ease-in all;
        border-radius: 1.25em;
        background: #fff linear-gradient(135deg, #f0f0f0, #fff 75%);
        box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    }
    
    .toggleButton__checkbox{
        display: none;
    }
    
    :checked + .toggleButton__body{
        background: #7ed321 linear-gradient(135deg, #8ee232, #7ed321);;
    }
    
    :checked + .toggleButton__body:before{
        content: "SHOW";
        text-align: left;
        color: #fff;
    }
    
    :checked + .toggleButton__body:after{
        left: calc(5em + 2px);
    }
}

.pdf-dashboard {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.button-add-new {
    float: right;
}
.pdf-dashboard-email {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.9);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-3 {
    padding: 30px;
}
.wrapper-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
}
.wrapper-box path {
    cursor: pointer;
    fill: red;
    transition: ease-in-out .3s;
}
.wrapper-box svg:hover path {
    cursor: pointer;
    fill: darkred;
}