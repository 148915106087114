 #page-faq .accordion .item .contentItem {
  text-align: center;
  position: relative;
  max-height: 0;
  z-index: 1000;
  text-align: left;
  overflow: hidden;
  transition: 1s ease max-height;
}

#page-faq .accordion .item  .contentItem-open {
  max-height: 600px;
}

#page-kariera #page-faq .accordion .item  .contentItem-open {
  max-height: 1500px;
}
