#modal-email-success .modal-dialog.map  {
    max-width: 900px;
}
.content-modal {
    background-image: url(../../assets/img/bg-modal.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: none!important;
    padding-top: 20px!important;
}

#modal-email-success h2 {
    color: white;
    text-align: center;
}
#modal-email-success .btn.btn-map{
    color: white;
    background-color: black;
}
#modal-email-success .btn.btn-map:hover{
    background-color: #dab74f;
    color: #000;
}
.vija {
    flex: none;
    margin-left: 8px;
}
.buttons::after{ 
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - 320px);
    margin-left: 160px;
    background-image: url(../../assets/img/vi.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.buttons {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.holder-anija {
    position: relative;
    height: 150px;
    margin: 0 75px 15px;
}
.move-anija {
    position: absolute;
    top: 50%;
    transition: all .4s ease-in-out;
    transform: translate(0%, -50%);
    text-align: center;
}
@media all and (max-width:700px){
    .btn-map.btn.btn-arrow:after {
        right: 15px;
    }
}