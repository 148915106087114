#page-team {
    display: flex;
    justify-content: center;
    width: 100%;
}

.wrapper {
    display: inline-block;
    margin-right: 16px;
    margin-left: 16px;
    width: calc(33% - 32px);
    text-align: center;
}

.card-team-img {
    width: 100%;
	height: 100%;
    object-fit: cover;	
	position: absolute;	
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotateY(90deg);
	transform-origin: 50%;
	background: rgba(255,255,255,0.9);	
	opacity: 0;
	transition: all 0.4s ease-in;
    z-index: 3;
}
.card-team {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    overflow: hidden;
    perspective: 500px; 
}

/* #hover-img p:hover + .holder-fream .card-team-img, */
.holder-fream:hover .card-team-img {
    transform: translate(-50%, -50%) rotateY(0deg);
	opacity: 1;
    z-index: 10;
}
.holder-fream .img-hover {
    position: relative;
}
.holder-fream .img-hover::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .3);
    background-image: url(../../assets/img/team/bg.svg);
    background-position: center;
    z-index: 3;
}

.team-wrapper .content {
    max-width: 1100px;
    padding: 250px 200px;
    /* padding-top: 150px;
    padding-bottom: 150px;  */
    margin-left: auto;
    margin-right: auto;
    background-image: url(../../assets/img/letra.webp);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.team-wrapper {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px; 
    /* padding-top: 150px;
    padding-bottom: 150px; */
    /* max-width: 1200px; */
    background-image: url(../../assets/img/team/bg_2.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.team-wrapper-2 {
    padding-top: 150px;
    padding-bottom: 150px;
    /* max-width: 1200px; */
    width: 100%;
    background-image: url(../../assets/img/team/bg_11.webp);
    background-position: center;
    background-size: contain;
    background-repeat: repeat-y;
}
.team-wrapper-2 .content {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.team-wrapper p {
    font-size: 22px;
    font-weight: 500;
    color: #000;
    margin-bottom: 50px;
    position: relative;
}



.team-wrapper:before{
    content:" ";
    position: absolute;
    top: 0;
    right: 0;
    height:100%;
    width: 30%;
    background:url(../../assets/img/cc/ana-e-djatht.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.team-wrapper:after{
    content:" ";
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 30%;
    background:url(../../assets/img/cc/majta.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
}

.team-wrapper .konopi:before{
    content:" ";
    height:47px;
    max-width:100%;
    display:block;
    width:50%;
    background:url(../../assets/img/hr_rope.svg) 100% center/100% no-repeat;
    margin:0 auto 65px
}

.team-cz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-img {
    position: relative;
    object-fit: contain;
    display: inline-block;
    max-height: 450px;
    padding: 30px;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
}

.rope {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -60px;
    z-index: 20;
}

#page-team .page-heading{
    font-weight:700;
    margin:0;
    font-size:56px;
    line-height:56px;
    color: #fff;
}

.card-team-frame {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: contain;
}

.holder-fream {
    position: relative;
    height: 100%;
    max-width: 300px;
    margin: 0 auto;
    height: 400px;
    padding-top: 52px;
    padding-left: 28px;
    padding-bottom: 15px;
    padding-right: 30px;
    /* background-image: url(../../assets/img/team/fream-1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}

.team .title {
    position: relative;
    font-size: 26px;
    line-height: 15px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0px;
}
.team .positon {
    position: relative;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    padding-bottom: 15;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}
.pb-20 {
    padding-bottom: 15px;
}

.team .email {
    font-size: 21px;
    font-weight: 400;
    color: #fff;
}
.team-sub-title {
    margin: 0 16px;
    width: 30%;
}
.team-sub-title h2.sub-heading {
    position: relative;
    height: 100%;
    padding-left: 16px;
    max-width: 300px;
    margin: 0 auto;
    font-weight:700;
    font-size:36px;
    line-height:35px;
    color: #fff;
}
.teamImg {
    width: 60%;
    overflow: hidden;
}
.teamImgFull {
    width: 100%;
    overflow: hidden;
}

.slider-img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.slick-track .slick-slide .bg-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1;
}

@media (max-width:1750px){
    .team-wrapper:after,
    .team-wrapper:before {
        width: 25%;
    }
}

@media (max-width:1450px){
    .team-wrapper:after,
    .team-wrapper:before {
        width: 20%;
        background-repeat: repeat-y;
    }
    .rope {
        bottom: -40px;
    }
    .team-wrapper:before{
        margin-right: 10px;
        right: -10px;
    }
    .team-wrapper:after{
        margin-left: 10px;
        left: -15px;
    }
}

@media (max-width:1200px){
    .team-it {
        display: inline;
    }
    .wrapper {
        width: calc(50% - 32px);
    }
    /* .team-sm #teamImg {
        max-width: 60%;
    } */
}

@media (max-width:1000px){
    .team-wrapper:after,
    .team-wrapper:before {
        top: 20px;
        width: 20%;
        background-position: top;
        /* background-repeat: repeat-y; */
    }
    .team-wrapper p {
        font-size: 22px;
        margin-bottom: 50px;
    }
    .team-wrapper .konopi:before {
        margin-bottom: 30px;
    }
    .team-wrapper .content {
        padding: 150px;
        background-size: cover;
    }
}

@media (max-width: 787px){
    .teamImg {
        width: 100%;
        overflow: hidden;
    }
    .team-wrapper:after,
    .team-wrapper:before {
        display: none;
    }
    .team-wrapper {
        padding-top: 0;
        padding-bottom: 0;
    }
    .team-wrapper p {
        font-size: 20px;
        margin-bottom: 0;
    }
    .team-wrapper .content {
        padding: 100px 30px;
        background-size: cover;
        background-image: url(../../assets/img/story_bg-xs-min.jpg);
        background-repeat: repeat-y;
        background-size: contain;
    }
    .rope {
        bottom: -10px;
    }
    .wrapper {
        width: calc(100% - 32px);
    }
    /* .team-sm #teamImg {
        max-width: 100%;
    } */
    .team-cz {
        display: inline;
    }
    .team-img { 
        max-width: 100%;
    }
    .team-wrapper-2 {
        padding-top: 50px;
        padding-bottom: 50px
    }
    #page-team .page-heading {
        font-size: 38px;
        padding: 0 50px;
    }
    .team-sub-title h2.sub-heading {
        font-size: 30px;
    }
    .holder-fream {
        height: 380px;
        max-width: 250px;
        padding-top: 37px;
        padding-left: 23px;
        padding-bottom: 75px;
        padding-right: 24px;
    }
    .card-team-frame {
        object-fit: contain;	
    }
}

