.dashboard-client {
    max-width: 350px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.mr-3 {
    margin-right: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}

.set-test-center {
    text-align: center;
    input {
        width: 100%;
    }
}

@media (max-width:767px){
    .mb-sm-20 {
        margin-bottom: 20px;
    }
    .show-on-desktop {
        display: none;
    }
}