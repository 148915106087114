.animationLoader {
    animation: dropdown 250ms ease-in;
  }
  
  @keyframes dropdown {
    0% {
      opacity: 1;
    }
    50% {
        opacity: .5;
    }
  
    100% {
      opacity: 0;
    }
  }