
#arrow-down {
    animation: dropdown 2000ms linear infinite;
  }
  
  @keyframes dropdown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
  
    100% {
        transform: translateY(0);
    }
  }