#map-xs-wrap .accordion .item .contentItem {
  text-align: center;
  position: relative;
  max-height: 0;
  z-index: 1000;
  text-align: left;
  overflow: hidden;
  margin-right: -40px;
  transition: 0.5s ease max-height;
  text-align: center;
}

#map-xs-wrap .accordion .item  .contentItem-open {
  transition: 0.5s all ease-in-out;
  padding: 35px 0 0 0;
  max-height: 600px;
}

#map-xs-wrap .accordion .item .contentItem table {
  width: 100%;
  margin-bottom: 60px;
  text-align: left;
  transition: 0.5s all ease-in-out;
}

#map-xs-wrap .accordion .item .contentItem .btn-gold {
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}
