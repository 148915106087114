	 
/* @z-index fix (needed for older IE browsers)
----------------------------------------*/

 body              {z-index:0;}
 .b-menu           {z-index:100;}
 .b-selector       {z-index:100;}
 .booklet          {z-index:10;}
 .b-pN             {z-index:10;}
 .b-p0             {z-index:30;}
 .b-p1             {z-index:20;}
 .b-p2             {z-index:20;}
 .b-p3             {z-index:30;}
 .b-p4             {z-index:10;}
 .b-prev           {z-index:40;}
 .b-next           {z-index:40;}
 .b-counter        {z-index:40;}
 
/* @Menu Items
----------------------------------------*/
 .b-menu {height:40px; padding:0 0 10px;}
 
 .b-selector             {height:40px; position:relative; float:right; border:none; color:#cecece; cursor:pointer; font:normal 12px "Myriad Pro", Myriad, "DejaVu Sans Condensed","Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;}
 .b-selector .b-current  {padding:8px 15px 12px; line-height:20px; min-width:18px; height:20px; display:block; background:#000; text-align:center;}
 .b-selector-page        {width:auto; margin-left:15px;}
 .b-selector-chapter     {width:auto;}
 
 .b-selector:hover            {color:#fff; background-position:left 0;}
 .b-selector:hover .b-current {background-position:right 0;}
 .b-selector ul               {overflow:hidden; margin:0; list-style:none !important; position:absolute; top:40px; right:0; padding:0 0 10px; background:#000; width:240px;}
 .b-selector li               {border:none;}
 .b-selector a                {color:#cecece; height:14px; text-decoration:none; display:block; padding:5px 10px;}
 .b-selector a .b-text        {float:left; clear:none;}
 .b-selector a .b-num         {float:right; clear:none;}
 .b-selector a:hover          {color:#fff;}

 .w-100 {
	width: 100%;
 }
 .h-100 {
	height: 100%;
 }
 #mybook .stf__parent {
	 margin-left: auto;
	 margin-right: auto;
	 height: 100%;
}

.imgCustomStyle {
	margin-top: -2.5vw !important;
	width: 70% !important; 
	margin-left: 15% !important;
}

.m-t--40 {
	margin-top: -40px!important;
}

.b-controls {
	margin-top: 0px;
	position: absolute;
	width: 100%;
}

@media (min-width:1408px){
	.b-controls {
		margin-top: -12px;
	}
}