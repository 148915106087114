@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
  will-change: trasnform;
}
@mixin translateY($x) {
  -webkit-transform: translateY($x);
  -moz-transform: translateY($x);
  -ms-transform: translateY($x);
  -o-transform: translateY($x);
  transform: translateY($x);
  will-change: trasnform;
}
@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -moz-transform: translateX($x);
  -ms-transform: translateX($x);
  -o-transform: translateX($x);
  transform: translateX($x);
  will-change: trasnform;
}
@mixin scale($scale) {
  -webkit-transform: scale($scale, $scale);
  -moz-transform: scale($scale, $scale);
  -ms-transform: scale($scale, $scale);
  -o-transform: scale($scale, $scale);
  transform: scale($scale, $scale);
  will-change: trasnform;
}
@mixin translate-rotate($x, $y, $rotate) {
  -webkit-transform: translate($x, $y) rotate($rotate);
  -moz-transform: translate($x, $y) rotate($rotate);
  -ms-transform: translate($x, $y) rotate($rotate);
  -o-transform: translate($x, $y) rotate($rotate);
  transform: translate($x, $y) rotate($rotate);
  will-change: trasnform;
}
@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
  will-change: trasnform;
}
@mixin rotateX($rotate) {
  -webkit-transform: rotateX($rotate);
  -moz-transform: rotateX($rotate);
  -ms-transform: rotateX($rotate);
  -o-transform: rotateX($rotate);
  transform: rotateX($rotate);
  will-change: trasnform;
}
@mixin rotateY($rotate) {
  -webkit-transform: rotateY($rotate);
  -moz-transform: rotateY($rotate);
  -ms-transform: rotateY($rotate);
  -o-transform: rotateY($rotate);
  transform: rotateY($rotate);
  will-change: trasnform;
}
@mixin translate-rotate-scale($x, $y, $rotate, $scale) {
  -webkit-transform: translate($x, $y) rotate($rotate) scale($scale, $scale);
  -moz-transform: translate($x, $y) rotate($rotate) scale($scale, $scale);
  -ms-transform: translate($x, $y) rotate($rotate) scale($scale, $scale);
  -o-transform: translate($x, $y) rotate($rotate) scale($scale, $scale);
  transform: translate($x, $y) rotate($rotate) scale($scale, $scale);
  will-change: trasnform;
}
@mixin transition($target, $duration) {
  -webkit-transition: $target $duration ease-in-out;
  -moz-transition: $target $duration ease-in-out;
  -o-transition: $target $duration ease-in-out;
  transition: $target $duration ease-in-out;
}
@mixin transition-effect($target, $duration, $effect) {
  -webkit-transition: $target $duration $effect;
  -moz-transition: $target $duration $effect;
  -o-transition: $target $duration $effect;
  transition: $target $duration $effect;
}
@mixin transition_linear($target, $duration) {
  -webkit-transition: $target $duration linear;
  -moz-transition: $target $duration linear;
  -o-transition: $target $duration linear;
  transition: $target $duration linear;
}
@mixin double-transition($target, $second-target, $duration){
  -webkit-transition: $target $duration ease-in-out, $second-target $duration ease-in-out;
  -moz-transition: $target $duration ease-in-out, $second-target $duration ease-in-out;
  -o-transition: $target $duration ease-in-out, $second-target $duration ease-in-out;
  transition: $target $duration ease-in-out, $second-target $duration ease-in-out;
}
@mixin transition-effect($target, $duration, $effect) {
  -webkit-transition: $target $duration $effect;
  -moz-transition: $target $duration $effect;
  -o-transition: $target $duration $effect;
  transition: $target $duration $effect;
}
@mixin shadow($targets) {
  -webkit-box-shadow: $targets;
  -moz-box-shadow: $targets;
  box-shadow: $targets;
}
@mixin multi-transform($targets) {
  -webkit-transform: $targets;
  -moz-transform: $targets;
  -ms-transform: $targets;
  -o-transform: $targets;
  transform: $targets;
  will-change: trasnform;
}
@mixin lh-unitless($lh, $fs) {
  line-height: calc($lh / $fs);
}
//Mixin for adding margin at 'Why Helvengo?' section.
@mixin marginListItemRecursively($starting, $ending, $ml) {
  @for $i from $starting through $ending {
    &:nth-child(#{1 + $i}){
      margin-left: $ml * $i;
    }
  }
}
@mixin unselectable-text() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}
